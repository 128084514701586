var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "week-nothing", attrs: { "align-v": "stretch" } },
    [
      _vm.question.type == "exitmodal"
        ? _c("b-col", { attrs: { "align-self": "center" } }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-column mx-auto",
                staticStyle: { "max-width": "768px" },
              },
              [
                _c("h2", { staticClass: "question-title-v2 mb-3 text-white" }, [
                  _vm._v(" " + _vm._s(_vm.question.title) + " "),
                ]),
                _c("p", { staticClass: "sub-text group-mb mb-4 text-white" }, [
                  _vm._v(" " + _vm._s(_vm.question.question) + " "),
                ]),
                _c(
                  "b-row",
                  { staticClass: "mt-5 mb-5", attrs: { "align-v": "center" } },
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "auto" } },
                      [
                        _c("b-avatar", {
                          attrs: {
                            variant: "primary",
                            src: _vm.question.doctor.profileUrl,
                            size: "7rem",
                            square: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("b-col", { attrs: { cols: "auto" } }, [
                      _c(
                        "h2",
                        {
                          staticClass: "mb-0 bold d-letter-spacing text-white",
                        },
                        [
                          _vm._v(
                            " Dr. " +
                              _vm._s(_vm.question.doctor.firstname) +
                              " " +
                              _vm._s(_vm.question.doctor.lastname) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "px-6",
                        attrs: { pill: "", variant: "info", size: "lg" },
                        on: { click: _vm.submitAnswer },
                      },
                      [_vm._v(" Complete ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _c(
            "b-col",
            {
              staticClass: "d-flex flex-column",
              attrs: { "align-self": "center" },
            },
            [
              _c("h2", { staticClass: "text-center question-title-v2 mb-2" }, [
                _vm._v(_vm._s(_vm.question.title) + ","),
              ]),
              _vm.question.description
                ? _c(
                    "p",
                    { staticClass: "sub-text group-mb text-center mb-4" },
                    [_vm._v(" " + _vm._s(_vm.question.description) + " ")]
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "align-self-center" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "px-6",
                      attrs: {
                        pill: "",
                        variant: "info",
                        size: "lg",
                        disabled: _vm.disabled,
                      },
                      on: { click: _vm.submitAnswer },
                    },
                    [_vm._v("Continue")]
                  ),
                ],
                1
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <b-navbar
    toggleable="sm"
    fixed="top"
    style="box-shadow: none; z-index: 498"
    class="d-flex"
  >
    <b-navbar-brand class="c-logo relative mb-2">
      <img src="@/assets/images/logo.svg" alt="" />
    </b-navbar-brand>
    <b-button
      pill
      variant="light"
      @click="$emit('close')"
      class="close-followup-modal ml-auto mr-3 pa-2"
      ><img src="@/assets/images/logout.svg" class="mr-2" />Close</b-button
    >
  </b-navbar>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.navbar {
  height: 80px;
  border: 0;
  background-color: $quaternary;

  @include media-breakpoint-down(md) {
    height: auto;
  }
  @include media-breakpoint-down(lg) {
    padding: 10px;
  }

  @include media-breakpoint-up(xxl) {
    height: 80px * $scale-font;
  }
}

.c-logo {
  @include media-breakpoint-down(md) {
    margin: 0;
    padding-left: 1rem;
  }
}

.close-followup-modal {
  @include media-breakpoint-up(lg) {
    top: 0;
    padding: 7px 10px;
    font-size: 0.8rem;
    min-width: 40px !important;
    right: 2.5rem;
    img {
      height: 19px;
    }
  }

  @include media-breakpoint-up(xxl) {
    top: 0;
    margin-top: 0 !important;
    margin-right: 2rem !important;
    padding: 7px * $scale-font 10px * $scale-font;
    font-size: 0.8rem * $scale-font;
    right: 2.5rem * $scale-font;

    img {
      height: 19px * $scale-font;
    }
  }
  position: relative;
  min-width: 40px !important;
  background-color: #fbfff9;
  letter-spacing: 1px;
  text-transform: capitalize;
  box-shadow: 0px 4px 6px #d0e7c2;
  border-radius: 15px;
  font-weight: normal;
  top: -4px;
}
</style>

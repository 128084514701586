var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "chatbox" },
    [
      _c(
        "modal",
        {
          ref: "modal",
          attrs: {
            id: "updatedPrescription",
            size: "md",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-header intake-header",
              staticStyle: { padding: "0", border: "0" },
            },
            [
              _c(
                "b-container",
                { staticClass: "pl-0 pr-0", attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticClass: "text-right", attrs: { cols: "12" } },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide(
                                    "updatedPrescription"
                                  )
                                },
                              },
                            },
                            [
                              _c("b-icon", {
                                attrs: {
                                  icon: "x-circle-fill",
                                  variant: "primary",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("h2", { staticClass: "heading text-primary mb-2" }, [
            _vm._v("Updated Treatment"),
          ]),
          _vm.prescriptionMessage.custommessage
            ? _c("p", [
                _vm._v(
                  " The price of " +
                    _vm._s(
                      _vm.prescriptionMessage.custommessage.message.productname
                    ) +
                    " treatment has changed from $" +
                    _vm._s(
                      _vm.prescriptionMessage.custommessage.message.pricefrom
                    ) +
                    " to $" +
                    _vm._s(
                      _vm.prescriptionMessage.custommessage.message.priceto
                    ) +
                    ". "
                ),
              ])
            : _vm._e(),
          _vm.prescriptionMessage.custommessage
            ? _c("div", { staticClass: "products" }, [
                _c("div", { staticClass: "product-img" }, [
                  _c("img", {
                    attrs: {
                      src: _vm.prescriptionMessage.custommessage.message.image,
                      alt: "",
                    },
                  }),
                ]),
                _c("ul", { staticClass: "product-content" }, [
                  _c("li", [
                    _c("h2", { staticClass: "mb-2 sub-text bold-font" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.prescriptionMessage.custommessage.message
                              .productname
                          ) +
                          " "
                      ),
                    ]),
                    _c(
                      "p",
                      {
                        directives: [
                          {
                            name: "line-clamp",
                            rawName: "v-line-clamp",
                            value: 2,
                            expression: "2",
                          },
                        ],
                        staticClass: "mb-0",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.prescriptionMessage.custommessage.message
                                .description
                            ) +
                            " "
                        ),
                      ]
                    ),
                  ]),
                  _c("li", [
                    _c("p", { staticClass: "mb-0 text-primary" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.prescriptionMessage.custommessage.message
                              .pricestatement
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]
      ),
      _c("FollowupV5", {
        key: _vm.followupV5Key,
        ref: "modalv5",
        attrs: { messages: _vm.messages, patientid: _vm.userid },
        on: {
          hide: function ($event) {
            _vm.followupV5Key += 1
          },
        },
      }),
      _c(
        "modal",
        {
          ref: "modal",
          attrs: {
            id: "weekNotice",
            size: "xl",
            "dialog-class": "ten-week",
            "content-class": "ten-week-modal",
            centered: "",
            "hide-header": "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            "no-close-on-esc": "",
          },
        },
        [
          _c(
            "b-row",
            {
              staticClass: "ten-week-modal-content px-5",
              attrs: { "align-v": "stretch" },
            },
            [
              _c(
                "b-col",
                {
                  staticClass: "mt-3",
                  attrs: { cols: "12", "align-self": "start" },
                },
                [
                  _c(
                    "b-row",
                    { attrs: { "align-v": "center" } },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "1" } },
                        [
                          _vm.followUpMessage.length > 0 &&
                          _vm.followUpMessage[0].id != 8
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "back-btn",
                                  staticStyle: { "min-width": "inherit" },
                                  attrs: { size: "lg", variant: "link" },
                                  on: {
                                    click: _vm.getPreviousFollowUpQuestion,
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/images/small_back_arrow_black.svg"),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { staticClass: "d-flex justify-content-center" },
                        [
                          _c("img", {
                            staticClass: "d-none d-md-block",
                            attrs: {
                              src: require("@/assets/images/logo_black.svg"),
                              alt: "",
                            },
                          }),
                          _c("img", {
                            staticClass: "d-block d-md-none",
                            attrs: {
                              src: require("@/assets/images/logo_black.svg"),
                              width: "120",
                              alt: "",
                            },
                          }),
                        ]
                      ),
                      _c("b-col", { attrs: { cols: "1" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", "align-self": "center" } },
                [
                  _vm.followUpType == "nothing"
                    ? _c("WeekNone", {
                        attrs: {
                          question: _vm.followUpMessage[0],
                          composite: _vm.composite,
                        },
                        on: { submitAnswer: _vm.answerFollowUpQuestion },
                      })
                    : _vm._e(),
                  _vm.followUpType == "single"
                    ? _c("WeekSingle", {
                        attrs: {
                          question: _vm.followUpMessage[0],
                          composite: _vm.composite,
                        },
                        on: { submitAnswer: _vm.answerFollowUpQuestion },
                      })
                    : _vm._e(),
                  _vm.followUpType == "text"
                    ? _c("WeekText", {
                        attrs: {
                          question: _vm.followUpMessage[0],
                          composite: _vm.composite,
                        },
                        on: { submitAnswer: _vm.answerFollowUpQuestion },
                      })
                    : _vm._e(),
                  _vm.composite
                    ? _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _vm.composite &&
                          _vm.secondQuestionBody.answertype == "text" &&
                          _vm.followUpMessage[1].requiredifvalue ==
                            _vm.firstAnswer
                            ? _c("WeekText", {
                                attrs: { question: _vm.secondQuestionBody },
                                on: {
                                  submitAnswer: _vm.answerFollowUpQuestion,
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "text-center onboarding-footer",
                  staticStyle: { "margin-bottom": "-1.5rem" },
                  attrs: { "align-self": "end" },
                },
                [_c("b", [_vm._v("Treatment Review")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "chat-headlines mx-auto" }, [
        _c("div", { staticStyle: { "max-width": "1150px" } }, [
          _vm.getDoctorName(_vm.userid)
            ? _c("div", { staticClass: "mb-0 d-flex" }, [
                _c(
                  "div",
                  { staticClass: "text-right pr-3 d-flex align-items-center" },
                  [
                    _c("b-avatar", {
                      attrs: {
                        src: _vm.getMemberProfileUrl(
                          _vm.getDoctorID(_vm.userid)
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _c("div", [
                  _c("b", [_vm._v(_vm._s(_vm.getDoctorName(_vm.userid)))]),
                  _c("br"),
                  _c("span", { staticClass: "text-muted" }, [
                    _vm._v("Winona Physician"),
                  ]),
                ]),
              ])
            : _c("p"),
        ]),
      ]),
      _c("ul", { staticClass: "chat-holder list-unstyled mb-0" }, [
        _c(
          "li",
          {
            class: ["chat-box-container", _vm.imgUpload ? "uploading-img" : ""],
          },
          [
            _c(
              "div",
              {
                class: ["chat-box", _vm.mobileClass],
                attrs: { id: "chat-box" },
                on: {
                  "&scroll": function ($event) {
                    return _vm.messageScroll.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "chat-holder" },
                  [
                    [
                      _c(
                        "div",
                        { staticClass: "chat" },
                        _vm._l(
                          _vm.groupMessage,
                          function (messages, sentAt, index) {
                            return _c(
                              "div",
                              { key: sentAt },
                              [
                                _c("div", { staticClass: "text-center" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.formattedTimestamp(sentAt)) +
                                      " "
                                  ),
                                ]),
                                _vm._l(messages, function (chat, chatIdx) {
                                  return _c(
                                    "div",
                                    { key: chatIdx },
                                    [
                                      _c(
                                        "div",
                                        {
                                          class: [
                                            chat.userid == _vm.userid
                                              ? "patient"
                                              : "doctor",
                                            "chat-bubble",
                                          ],
                                        },
                                        [
                                          chat.message &&
                                          chat.type !=
                                            "prescription_confirmation" &&
                                          chat.type !=
                                            "prescription_followup" &&
                                          chat.type != "reassignment_transfer"
                                            ? _c(
                                                "div",
                                                {
                                                  class: [
                                                    chat.userid == _vm.userid
                                                      ? "patient"
                                                      : "doctor",
                                                    "msg",
                                                  ],
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "mb-0 d-letter-spacing",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(chat.message)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.groupBy(
                                              chat.attachment,
                                              _vm.amountToGroupBy
                                            ),
                                            function (group, groupIndex) {
                                              return _c(
                                                "b-row",
                                                {
                                                  key: groupIndex,
                                                  class: [
                                                    chat.userid == _vm.userid
                                                      ? "patient"
                                                      : "doctor",
                                                    "msg",
                                                    "p-0",
                                                  ],
                                                  staticStyle: {
                                                    "background-color":
                                                      "transparent",
                                                  },
                                                  attrs: { "no-gutters": "" },
                                                },
                                                [
                                                  _vm._l(
                                                    group,
                                                    function (attach, i) {
                                                      return [
                                                        attach.type.includes(
                                                          "image"
                                                        ) &&
                                                        ![
                                                          "tiff",
                                                          "heic",
                                                          "svg",
                                                          "x-xbitmap",
                                                        ].some((type) =>
                                                          attach.type.includes(
                                                            type
                                                          )
                                                        )
                                                          ? _c(
                                                              "b-col",
                                                              {
                                                                key: attach + i,
                                                              },
                                                              [
                                                                _c("b-img", {
                                                                  attrs: {
                                                                    thumbnail:
                                                                      "",
                                                                    fluid: "",
                                                                    src: attach.url,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : [
                                                              _c(
                                                                "a",
                                                                {
                                                                  key:
                                                                    attach + i,
                                                                  staticClass:
                                                                    "p-2 d-block d-letter-spacing",
                                                                  attrs: {
                                                                    href: attach.url,
                                                                    variant:
                                                                      "link",
                                                                    download:
                                                                      "",
                                                                    target:
                                                                      "_blank",
                                                                  },
                                                                },
                                                                [
                                                                  _c("b-icon", {
                                                                    attrs: {
                                                                      icon: "arrow-down-circle-fill",
                                                                      variant:
                                                                        "primary",
                                                                    },
                                                                  }),
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        attach.filename
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                      ]
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      chat.type !=
                                        "prescription_confirmation" &&
                                      chat.type != "prescription_followup" &&
                                      chat.type != "reassignment_transfer"
                                        ? _c(
                                            "div",
                                            {
                                              class: [
                                                chat.userid != _vm.userid
                                                  ? "is-doctor"
                                                  : "",
                                                "chat-img",
                                              ],
                                            },
                                            [
                                              chat.userid != _vm.userid
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "img-holder",
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          src: _vm.getMemberProfileUrl(
                                                            chat.userid
                                                          ),
                                                          alt: "",
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(
                                                "div",
                                                { staticClass: "doctor-info" },
                                                [
                                                  chat.userid != _vm.userid
                                                    ? _c(
                                                        "h3",
                                                        {
                                                          staticClass:
                                                            "mb-0 bold d-letter-spacing",
                                                          staticStyle: {
                                                            color: "#333333",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.getMemberName(
                                                                  chat.userid
                                                                )
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "small",
                                                    {
                                                      staticClass:
                                                        "text-muted d-letter-spacing",
                                                      staticStyle: {
                                                        "font-size": "12px",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.moment
                                                            .unix(chat.sentat)
                                                            .format(
                                                              "MMM DD, YYYY hh:mm A"
                                                            )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      chat.message != "null" &&
                                      chat.type === "prescription_followup"
                                        ? _c(
                                            "b-card",
                                            {
                                              staticClass:
                                                "new-prescription text-center",
                                            },
                                            [
                                              _c(
                                                "p",
                                                { staticClass: "sub-text" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        chat.custommessage
                                                          .message
                                                          .systemreply !=
                                                          undefined
                                                          ? chat.custommessage
                                                              .message
                                                              .systemreply
                                                          : chat.custommessage
                                                              .message.text
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              chat.message != "null" &&
                                              chat.type ===
                                                "prescription_followup" &&
                                              chat.custommessage.message
                                                .systemreply == undefined
                                                ? _c(
                                                    "b-button",
                                                    {
                                                      staticClass:
                                                        "pending-btn",
                                                      staticStyle: {
                                                        "min-width": "200px",
                                                      },
                                                      attrs: {
                                                        pill: "",
                                                        variant: "info",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.onFollowUpStart(
                                                            chat
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          chat.custommessage
                                                            .message
                                                            .systemreply !=
                                                            undefined
                                                            ? "View answers"
                                                            : "Continue"
                                                        )
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      chat.message != "null" &&
                                      chat.type === "prescription_confirmation"
                                        ? _c(
                                            "b-card",
                                            {
                                              staticClass:
                                                "new-prescription text-center",
                                            },
                                            [
                                              chat.actionstatus === "PENDING"
                                                ? _c(
                                                    "p",
                                                    { staticClass: "sub-text" },
                                                    [
                                                      _vm._v(
                                                        " Your treatment has been changed, please review "
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "b-modal",
                                                              rawName:
                                                                "v-b-modal.updatedPrescription",
                                                              modifiers: {
                                                                updatedPrescription: true,
                                                              },
                                                            },
                                                          ],
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.setPrescriptionMessage(
                                                                chat.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" here. ")]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              chat.actionstatus === "ACCEPTED"
                                                ? _c(
                                                    "p",
                                                    { staticClass: "sub-text" },
                                                    [
                                                      _vm._v(
                                                        " You've accepted your treatment, please review "
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "b-modal",
                                                              rawName:
                                                                "v-b-modal.updatedPrescription",
                                                              modifiers: {
                                                                updatedPrescription: true,
                                                              },
                                                            },
                                                          ],
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.setPrescriptionMessage(
                                                                chat.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" here. ")]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              chat.actionstatus === "DECLINED"
                                                ? _c(
                                                    "p",
                                                    { staticClass: "sub-text" },
                                                    [
                                                      _vm._v(
                                                        " You've declined your treatment, please review "
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "b-modal",
                                                              rawName:
                                                                "v-b-modal.updatedPrescription",
                                                              modifiers: {
                                                                updatedPrescription: true,
                                                              },
                                                            },
                                                          ],
                                                          attrs: { href: "#" },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.setPrescriptionMessage(
                                                                chat.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v(" here. ")]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              chat.actionstatus === "PENDING"
                                                ? _c(
                                                    "b-row",
                                                    {
                                                      attrs: {
                                                        "align-h": "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                          attrs: {
                                                            cols: "12",
                                                            md: "6",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "pending-btn accept",
                                                              staticStyle: {
                                                                "min-width":
                                                                  "200px",
                                                              },
                                                              attrs: {
                                                                pill: "",
                                                                variant:
                                                                  "outline-info",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.performMessageAction(
                                                                      chat.id,
                                                                      "ACCEPT"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("ACCEPT")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass:
                                                            "text-left",
                                                          attrs: {
                                                            cols: "12",
                                                            md: "6",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              staticClass:
                                                                "pending-btn decline",
                                                              staticStyle: {
                                                                "min-width":
                                                                  "200px",
                                                              },
                                                              attrs: {
                                                                pill: "",
                                                                variant: "info",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.performMessageAction(
                                                                      chat.id,
                                                                      "DECLINE"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [_vm._v("DECLINE")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      chat.message != "null" &&
                                      chat.type === "reassignment_transfer"
                                        ? _c(
                                            "b-card",
                                            {
                                              staticClass:
                                                "new-prescription text-center",
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "sub-text mb-1",
                                                },
                                                [
                                                  _vm._v(
                                                    " You have been reassigned to Dr. " +
                                                      _vm._s(
                                                        chat.custommessage
                                                          .message.doctorto
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                              chat.custommessage.message
                                                .assignmenttype ===
                                                "TEMPORARY" &&
                                              chat.custommessage.message
                                                .reassignmentstatus !==
                                                "RETURNED"
                                                ? _c(
                                                    "p",
                                                    { staticClass: "mb-0" },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.moment
                                                              .unix(
                                                                chat
                                                                  .custommessage
                                                                  .message
                                                                  .startdatestamp
                                                              )
                                                              .format(
                                                                "MMM DD, YYYY hh:mm A"
                                                              )
                                                          ) +
                                                          " to " +
                                                          _vm._s(
                                                            _vm.moment
                                                              .unix(
                                                                chat
                                                                  .custommessage
                                                                  .message
                                                                  .enddatestamp
                                                              )
                                                              .format(
                                                                "MMM DD, YYYY hh:mm A"
                                                              )
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                                index ===
                                  Object.keys(_vm.groupMessage).length - 1 &&
                                _vm.isUploading
                                  ? _c(
                                      "div",
                                      { staticClass: "text-center" },
                                      [_c("b-spinner", { attrs: { big: "" } })],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          }
                        ),
                        0
                      ),
                    ],
                    !Boolean(_vm.getDoctorName(_vm.userid)) &&
                    !_vm.loadingMessage
                      ? _c(
                          "div",
                          {
                            staticClass: "text-center py-1",
                            staticStyle: {
                              "background-color": "#ffecca",
                              color: "#ff8d40",
                            },
                          },
                          [
                            _vm._v(
                              " The doctor will respond to your chats once you are verified "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            ),
            _c(
              "b-alert",
              {
                staticClass: "text-center",
                attrs: { show: _vm.alert.connection.show, variant: "danger" },
              },
              [
                _vm._v(" You are currently disconnected. Click "),
                _c(
                  "a",
                  {
                    staticClass: "text-light",
                    attrs: { href: "javascript:;" },
                    on: { click: _vm.reconnectApollo },
                  },
                  [_c("u", [_c("b", [_vm._v("reconnect")])])]
                ),
                _vm._v(" to continue messaging. "),
              ]
            ),
            _vm.conversation &&
            _vm.conversation.isbanned &&
            !_vm.isBanWithinOneMonth
              ? _c("div", { staticClass: "message text-center" }, [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      " Your ability to message this doctor has been restricted due to a violation of our code of conduct. "
                    ),
                  ]),
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      " Please seek medical assistance from another healthcare provider. "
                    ),
                  ]),
                ])
              : _c(
                  "div",
                  { ref: "sendMessage", staticClass: "message" },
                  [
                    _c(
                      "b-form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.sendMessage()
                          },
                        },
                      },
                      [
                        _c("b-container", { staticClass: "px-0" }, [
                          _c("div", { staticClass: "message-holder" }, [
                            _c("div", { staticClass: "textarea-box" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.newMessage,
                                    expression: "newMessage",
                                  },
                                ],
                                ref: "typeMessage",
                                staticClass: "chat-textbox",
                                attrs: { placeholder: "Type a message" },
                                domProps: { value: _vm.newMessage },
                                on: {
                                  blur: function ($event) {
                                    return _vm.hideLabel()
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.newMessage = $event.target.value
                                  },
                                },
                              }),
                              _c("div", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "p-3 h-100",
                                    attrs: { href: "#" },
                                    on: { click: _vm.openUpload },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/paperclip_round.svg"),
                                        alt: "",
                                      },
                                    }),
                                  ]
                                ),
                                _c("input", {
                                  ref: "fileInput",
                                  staticStyle: { display: "none" },
                                  attrs: { type: "file", f: "", multiple: "" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleFilesUpload()
                                    },
                                  },
                                }),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "button-holder d-flex align-items-center",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "d-none d-lg-flex" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticStyle: {
                                          width: "50px",
                                          height: "46px",
                                        },
                                        attrs: {
                                          id: "send-button",
                                          pill: "",
                                          block: "",
                                          type: "submit",
                                          variant: "link",
                                          disabled:
                                            _vm.hasError ||
                                            _vm.isSending ||
                                            _vm.isMessageBodyEmpty,
                                        },
                                      },
                                      [
                                        _vm.isSending
                                          ? _c("b-spinner", {
                                              attrs: { small: "" },
                                            })
                                          : _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/send.svg"),
                                                alt: "",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                !_vm.isMessageBodyEmpty && !_vm.isSending
                                  ? _c(
                                      "b-tooltip",
                                      { attrs: { target: "send-button" } },
                                      [_vm._v(" Send message ")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "d-lg-none d-flex" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          pill: "",
                                          block: "",
                                          type: "submit",
                                          variant: "link",
                                          disabled:
                                            _vm.hasError ||
                                            _vm.isSending ||
                                            _vm.isMessageBodyEmpty,
                                        },
                                      },
                                      [
                                        _vm.isSending
                                          ? _c("b-spinner", {
                                              attrs: { small: "" },
                                            })
                                          : _c("img", {
                                              attrs: {
                                                src: require("@/assets/images/send.svg"),
                                                alt: "",
                                              },
                                            }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _vm.attachments.length > 0
                      ? _c(
                          "b-container",
                          { ref: "uploadImg", staticClass: "pre-upload" },
                          _vm._l(_vm.attachments, function (attachment, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "img-holder" },
                              [
                                _c("b-icon", {
                                  staticClass: "close-btn",
                                  attrs: { icon: "x-circle-fill" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeFile(index)
                                    },
                                  },
                                }),
                                _vm.viewImage(attachment)
                                  ? _c("img", {
                                      attrs: {
                                        src: attachment.url,
                                        alt: attachment.name,
                                      },
                                    })
                                  : !_vm.viewImage(attachment)
                                  ? _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/pdf-logo.png"),
                                        alt: attachment.name,
                                      },
                                    })
                                  : _c("div", [
                                      !attachment.type.includes("image") ||
                                      attachment.type.includes("heic") ||
                                      attachment.type.includes("tiff") ||
                                      attachment.type.includes("x-xbitmap")
                                        ? _c(
                                            "svg",
                                            {
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                width: "32",
                                                height: "32",
                                                viewBox: "0 0 24 24",
                                              },
                                            },
                                            [
                                              _c("path", {
                                                attrs: {
                                                  fill: "currentColor",
                                                  d: "M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8.83c0-.53-.21-1.04-.59-1.41l-4.83-4.83c-.37-.38-.88-.59-1.41-.59H6zm7 6V3.5L18.5 9H14c-.55 0-1-.45-1-1z",
                                                },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                              ],
                              1
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ],
                  1
                ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      ref: "modalv5",
      attrs: {
        id: "weekNotice",
        size: "xl",
        "dialog-class": "ten-week new-version",
        "content-class": `ten-week-modal new-version ${
          _vm.isCompletionScreen ||
          (!_vm.isApplyDiscount && _vm.isMainCancellationScreen)
            ? "cancellation-main"
            : ""
        }`,
        centered: "",
        "hide-header": "",
        "hide-footer": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
      },
    },
    [
      _c("div", { ref: "modalParent" }),
      _c(
        "b-row",
        { staticClass: "ten-week-modal-content position-relative" },
        [
          _vm.followupList &&
          [
            1,
            ..._vm.mainCancellationScreenSteps,
            ..._vm.completionScreenSteps,
          ].includes(_vm.followup.id)
            ? _c(
                "b-button",
                {
                  staticClass: "position-absolute",
                  staticStyle: { right: "0", "z-index": "498" },
                  attrs: { size: "sm", variant: "link" },
                  on: { click: _vm.closeModal },
                },
                [
                  _c("b-icon", {
                    staticStyle: { width: "30px", height: "30px" },
                    attrs: { icon: "x-circle-fill", variant: "primary" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.followupList &&
          [
            1,
            ..._vm.mainCancellationScreenSteps,
            ..._vm.completionScreenSteps,
          ].includes(_vm.followup.id)
            ? _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex flex-column justify-content-between",
                          staticStyle: { height: "90vh" },
                        },
                        [
                          _c("div", [
                            _vm.isCompletionScreen ||
                            (!_vm.isApplyDiscount &&
                              _vm.isMainCancellationScreen)
                              ? _c("img", {
                                  staticClass: "d-none d-md-block",
                                  attrs: {
                                    src: require("@/assets/images/dark-icon-logo.svg"),
                                    alt: "",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "d-none d-md-block",
                                  attrs: {
                                    src: require("@/assets/images/icon-logo.svg"),
                                    alt: "",
                                  },
                                }),
                            _vm.isCompletionScreen ||
                            (!_vm.isApplyDiscount &&
                              _vm.isMainCancellationScreen)
                              ? _c("img", {
                                  staticClass: "d-block d-md-none",
                                  attrs: {
                                    src: require("@/assets/images/dark-icon-logo.svg"),
                                    width: "40",
                                    alt: "",
                                  },
                                })
                              : _c("img", {
                                  staticClass: "d-block d-md-none",
                                  attrs: {
                                    src: require("@/assets/images/icon-logo.svg"),
                                    width: "40",
                                    alt: "",
                                  },
                                }),
                          ]),
                          _c(
                            "p",
                            {
                              staticClass: "h3 text-uppercase",
                              class:
                                _vm.isCompletionScreen ||
                                (!_vm.isApplyDiscount &&
                                  _vm.isMainCancellationScreen)
                                  ? "text-plum"
                                  : "text-primary",
                              staticStyle: {
                                transform: "rotate(-90deg)",
                                "transform-origin": "top left",
                              },
                            },
                            [_vm._v(" Menopause Consultation ")]
                          ),
                        ]
                      ),
                      _c("b-col", { attrs: { cols: "1" } }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c("FollowupHeader", {
                attrs: { hide: false, step: "1", title: "Close modal" },
                on: { close: _vm.closeModal },
              }),
          _c(
            "b-container",
            {
              staticClass: "px-0",
              class:
                _vm.followupList &&
                [
                  1,
                  ..._vm.mainCancellationScreenSteps,
                  ..._vm.completionScreenSteps,
                ].includes(_vm.followup.id)
                  ? "d-flex justify-content-center align-items-center col-10"
                  : "col-12",
              attrs: { fluid: "" },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex justify-content-start",
                      staticStyle: {
                        "padding-top": "100px",
                        "flex-wrap": "wrap",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-lg-4 col-xl-4 col-xxl-4 col-12" },
                        [
                          _c(
                            "div",
                            { staticClass: "pl-lg-5 ml-lg-6" },
                            [
                              _vm.hasFollowupObject &&
                              _vm.followup.id != 8 &&
                              ![
                                1,
                                ..._vm.mainCancellationScreenSteps,
                                ..._vm.completionScreenSteps,
                              ].includes(_vm.followup.id) &&
                              !(_vm.followup.id === 12 && _vm.isApplyDiscount)
                                ? _c(
                                    "b-button",
                                    {
                                      staticClass: "back-btn mb-4",
                                      staticStyle: { "min-width": "inherit" },
                                      attrs: { size: "lg", variant: "link" },
                                      on: {
                                        click: _vm.getPreviousFollowUpQuestion,
                                      },
                                    },
                                    [
                                      _c("img", {
                                        staticStyle: { height: "20px" },
                                        attrs: {
                                          src: require("@/assets/images/small_back_arrow.svg"),
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.hasFollowupObject &&
                              ![
                                1,
                                ..._vm.mainCancellationScreenSteps,
                                ..._vm.completionScreenSteps,
                              ].includes(_vm.followup.id) &&
                              _vm.followup.id !== 8
                                ? _c("p", { staticClass: "onboarding-title" }, [
                                    _vm._v(" Health Questions "),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          class: {
                            "col-lg-5 col-xl-4 col-xxl-4 col-12":
                              _vm.followupList &&
                              ![
                                1,
                                ..._vm.mainCancellationScreenSteps,
                                ..._vm.completionScreenSteps,
                              ].includes(_vm.followup.id),
                          },
                        },
                        [
                          _vm.followUpType == "nothing"
                            ? _c("WeekNoneV2", {
                                attrs: {
                                  question: _vm.followup,
                                  composite: _vm.composite,
                                  disabled: _vm.buttonDisabled,
                                },
                                on: {
                                  submitAnswer: _vm.answerFollowUpQuestion,
                                },
                              })
                            : _vm._e(),
                          _vm.followUpType == "treatmentform"
                            ? _c("WeekTreatment", {
                                attrs: {
                                  body: _vm.followup,
                                  "parent-ref": _vm.$refs.modalParent,
                                },
                                on: {
                                  submitAnswer: _vm.answerFollowUpQuestion,
                                },
                              })
                            : _vm._e(),
                          _vm.followUpType == "single"
                            ? _c("WeekSingleV2", {
                                attrs: {
                                  question: _vm.followup,
                                  composite: _vm.composite,
                                  mainScreenSteps: [
                                    ..._vm.mainCancellationScreenSteps,
                                    ..._vm.completionScreenSteps,
                                  ],
                                  disabled: _vm.buttonDisabled,
                                  followupId: _vm.followUpID,
                                  patientid: _vm.patientid,
                                },
                                on: {
                                  submitAnswer: _vm.answerFollowUpQuestion,
                                  discount: _vm.onDiscount,
                                  getAnswer: _vm.setFollowUpMessage,
                                },
                              })
                            : _vm._e(),
                          _vm.followUpType == "text"
                            ? _c("WeekTextV2", {
                                attrs: {
                                  question: _vm.followup,
                                  composite: _vm.composite,
                                  disabled: _vm.buttonDisabled,
                                },
                                on: {
                                  submitAnswer: _vm.answerFollowUpQuestion,
                                },
                              })
                            : _vm._e(),
                          _vm.followUpType == "array"
                            ? _c("WeekMultiple", {
                                attrs: {
                                  body: _vm.followup,
                                  disabled: _vm.buttonDisabled,
                                },
                                on: {
                                  submitAnswer: _vm.answerFollowUpQuestion,
                                },
                              })
                            : _vm._e(),
                          _vm.followUpType == "symptoms"
                            ? _c("WeekSymptoms", {
                                key:
                                  _vm.hasFollowupObject &&
                                  _vm.followup.question,
                                attrs: {
                                  disabled: _vm.buttonDisabled,
                                  body: _vm.followup,
                                },
                                on: { submitSymptoms: _vm.submitSymptoms },
                              })
                            : _vm._e(),
                          _vm.composite
                            ? _c(
                                "div",
                                { staticClass: "mt-4" },
                                [
                                  _vm.composite &&
                                  _vm.secondQuestionBody.answertype == "text" &&
                                  _vm.followupList[1].requiredifvalue ==
                                    _vm.firstAnswer
                                    ? _c("WeekTextV2", {
                                        attrs: {
                                          question: _vm.secondQuestionBody,
                                          disabled: _vm.buttonDisabled,
                                        },
                                        on: {
                                          submitAnswer:
                                            _vm.answerFollowUpQuestion,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "col-lg-3 col-xl-4 col-xxl-4 col-12",
                      }),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "week-treatment" },
    [
      _c("b-col", { attrs: { cols: "3" } }),
      _c("b-col", [
        _c(
          "div",
          {
            staticClass: "d-flex flex-column mx-auto",
            staticStyle: { "max-width": "768px" },
          },
          [
            _vm.hasDHEA
              ? _c("h2", { staticClass: "question-title mb-5" }, [
                  _vm._v(
                    " Based on your current symptoms and preferences, this treatment option may be a great addition for you, if your Winona physician agrees. "
                  ),
                ])
              : _c("h2", { staticClass: "question-title mb-5" }, [
                  _vm._v(
                    " Based on your current symptoms and preferences, these treatment options may be a better solution for you, if your Winona physician agrees. "
                  ),
                ]),
            _vm.activePrescriptions.length > 0
              ? _c(
                  "section",
                  [
                    _c("p", { staticClass: "sub-text mb-3 bold-font" }, [
                      _vm._v("Current Treatment"),
                    ]),
                    _vm._l(_vm.activePrescriptions, function (prescription) {
                      return _c("CurrentTreatmentCard", {
                        key: prescription.id,
                        attrs: { prescription: prescription },
                      })
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm.hasDHEA
              ? _c(
                  "section",
                  [
                    _c("p", { staticClass: "sub-text mb-3 bold-font" }, [
                      _vm._v("Additional Treatment"),
                    ]),
                    _vm._l(_vm.products, function (prescription) {
                      return _c("CurrentTreatmentCard", {
                        key: prescription.id,
                        attrs: { prescription: prescription },
                      })
                    }),
                  ],
                  2
                )
              : _c(
                  "section",
                  [
                    _c("p", { staticClass: "sub-text mb-3 bold-font" }, [
                      _vm._v("Treatment"),
                    ]),
                    _vm._l(_vm.products, function (product) {
                      return _c("ProductRevealCheckbox", {
                        key: product.id,
                        ref: "ProductRevealCheckbox",
                        refInFor: true,
                        attrs: {
                          product: product,
                          desiredplan: _vm.desiredplan,
                        },
                        on: { "set-selection": _vm.updateSelection },
                      })
                    }),
                  ],
                  2
                ),
            _c(
              "section",
              { staticClass: "mb-5" },
              [
                _c("p", { staticClass: "sub-text mb-3 bold-font" }, [
                  _vm._v("Any questions for the doctor?"),
                ]),
                _c("b-form-textarea", {
                  staticClass: "onboarding-textarea-v2",
                  staticStyle: { width: "100%" },
                  attrs: {
                    id: "textarea",
                    rows: "4",
                    "max-rows": "4",
                    placeholder: "Write something",
                  },
                  model: {
                    value: _vm.doctorQuestions,
                    callback: function ($$v) {
                      _vm.doctorQuestions = $$v
                    },
                    expression: "doctorQuestions",
                  },
                }),
              ],
              1
            ),
            _c(
              "section",
              { staticClass: "mb-5" },
              [
                _c("h2", { staticClass: "question-title mb-5" }, [
                  _vm._v(
                    " Would you like us to let your doctor know your preference? "
                  ),
                ]),
                _c("w-checkbox", {
                  attrs: {
                    id: "1",
                    value: "1",
                    type: "radio",
                    label: "Yes please!",
                  },
                  model: {
                    value: _vm.answer,
                    callback: function ($$v) {
                      _vm.answer = $$v
                    },
                    expression: "answer",
                  },
                }),
                _c("w-checkbox", {
                  attrs: {
                    id: "2",
                    value: "2",
                    type: "radio",
                    label:
                      "I’m not sure - I’d like to message with my physician directly",
                  },
                  model: {
                    value: _vm.answer,
                    callback: function ($$v) {
                      _vm.answer = $$v
                    },
                    expression: "answer",
                  },
                }),
              ],
              1
            ),
            _c("section", [
              _c("p", { staticClass: "sub-text mb-3 bold-font" }, [
                _vm._v("How does this help?"),
              ]),
              _c(
                "div",
                _vm._l(_vm.blogs, function (blog) {
                  return _c(
                    "div",
                    {
                      key: blog.sys.id,
                      staticClass: "card-holder mb-5",
                      staticStyle: { background: "#fff" },
                    },
                    [
                      _c("div", { staticClass: "card-image-container" }, [
                        _c("img", {
                          attrs: {
                            src: blog.thumbnailWebp.url,
                            alt: blog.title,
                          },
                        }),
                      ]),
                      _c("div", { staticClass: "card-content" }, [
                        _c("p", { staticClass: "mb-2 article-tag" }, [
                          _vm._v(_vm._s(blog.category.title)),
                        ]),
                        _c(
                          "h2",
                          {
                            staticClass: "article-title mb-2",
                            staticStyle: { "font-size": "1.5rem" },
                          },
                          [_vm._v(" " + _vm._s(blog.title) + " ")]
                        ),
                        _c(
                          "a",
                          {
                            staticClass: "text-underline",
                            staticStyle: { "font-size": "1.25rem" },
                            attrs: { href: "#" },
                          },
                          [
                            _vm._v(" Learn More "),
                            _c("span", {
                              staticClass: "next-arrow-purple",
                              staticStyle: { "font-size": "1rem" },
                            }),
                          ]
                        ),
                      ]),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "week-text" }, [
    _c("h1", { staticClass: "text-center question-title-v2 mb-4" }, [
      _vm._v(" " + _vm._s(_vm.question.question) + " "),
    ]),
    _c(
      "div",
      { staticClass: "text-center" },
      [
        _c("b-form-textarea", {
          staticStyle: { width: "100%" },
          attrs: {
            id: "textarea",
            rows: "6",
            "max-rows": "6",
            placeholder: "Write something",
          },
          model: {
            value: _vm.answer,
            callback: function ($$v) {
              _vm.answer = $$v
            },
            expression: "answer",
          },
        }),
        _c(
          "b-button",
          {
            staticClass: "mt-3 onboarding-btn",
            attrs: { variant: "info", pill: "" },
            on: { click: _vm.submitAnswer },
          },
          [_vm._v("Continue")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <modal
    id="weekNotice"
    ref="modalv5"
    size="xl"
    dialog-class="ten-week new-version"
    :content-class="`ten-week-modal new-version ${
      isCompletionScreen || (!isApplyDiscount && isMainCancellationScreen)
        ? 'cancellation-main'
        : ''
    }`"
    centered
    hide-header
    hide-footer
    no-close-on-backdrop
    no-close-on-esc
  >
    <div ref="modalParent"></div>
    <b-row class="ten-week-modal-content position-relative">
      <b-button
        v-if="
          followupList &&
          [
            1,
            ...mainCancellationScreenSteps,
            ...completionScreenSteps,
          ].includes(followup.id)
        "
        size="sm"
        variant="link"
        @click="closeModal"
        class="position-absolute"
        style="right: 0; z-index: 498"
      >
        <b-icon
          icon="x-circle-fill"
          variant="primary"
          style="width: 30px; height: 30px"
        ></b-icon>
      </b-button>
      <b-col
        cols="2"
        v-if="
          followupList &&
          [
            1,
            ...mainCancellationScreenSteps,
            ...completionScreenSteps,
          ].includes(followup.id)
        "
      >
        <b-row>
          <b-col
            class="d-flex flex-column justify-content-between"
            style="height: 90vh"
          >
            <div>
              <img
                v-if="
                  isCompletionScreen ||
                  (!isApplyDiscount && isMainCancellationScreen)
                "
                class="d-none d-md-block"
                src="@/assets/images/dark-icon-logo.svg"
                alt=""
              />
              <img
                v-else
                class="d-none d-md-block"
                src="@/assets/images/icon-logo.svg"
                alt=""
              />
              <img
                v-if="
                  isCompletionScreen ||
                  (!isApplyDiscount && isMainCancellationScreen)
                "
                class="d-block d-md-none"
                src="@/assets/images/dark-icon-logo.svg"
                width="40"
                alt=""
              />
              <img
                v-else
                class="d-block d-md-none"
                src="@/assets/images/icon-logo.svg"
                width="40"
                alt=""
              />
            </div>
            <p
              class="h3 text-uppercase"
              :class="
                isCompletionScreen ||
                (!isApplyDiscount && isMainCancellationScreen)
                  ? 'text-plum'
                  : 'text-primary'
              "
              style="transform: rotate(-90deg); transform-origin: top left"
            >
              Menopause Consultation
            </p>
          </b-col>
          <b-col cols="1"></b-col>
        </b-row>
      </b-col>
      <FollowupHeader
        v-else
        @close="closeModal"
        :hide="false"
        step="1"
        :title="'Close modal'"
      />
      <b-container
        fluid
        class="px-0"
        :class="
          followupList &&
          [
            1,
            ...mainCancellationScreenSteps,
            ...completionScreenSteps,
          ].includes(followup.id)
            ? 'd-flex justify-content-center align-items-center col-10'
            : 'col-12'
        "
      >
        <b-row>
          <b-col
            class="d-flex justify-content-start"
            style="padding-top: 100px; flex-wrap: wrap"
          >
            <div class="col-lg-4 col-xl-4 col-xxl-4 col-12">
              <div class="pl-lg-5 ml-lg-6">
                <b-button
                  class="back-btn mb-4"
                  size="lg"
                  @click="getPreviousFollowUpQuestion"
                  variant="link"
                  style="min-width: inherit"
                  v-if="
                    hasFollowupObject &&
                    followup.id != 8 &&
                    ![
                      1,
                      ...mainCancellationScreenSteps,
                      ...completionScreenSteps,
                    ].includes(followup.id) &&
                    !(followup.id === 12 && isApplyDiscount)
                  "
                >
                  <img
                    style="height: 20px"
                    src="@/assets/images/small_back_arrow.svg"
                  />
                </b-button>
                <p
                  v-if="
                    hasFollowupObject &&
                    ![
                      1,
                      ...mainCancellationScreenSteps,
                      ...completionScreenSteps,
                    ].includes(followup.id) &&
                    followup.id !== 8
                  "
                  class="onboarding-title"
                >
                  Health Questions
                </p>
              </div>
            </div>
            <div
              :class="{
                'col-lg-5 col-xl-4 col-xxl-4 col-12':
                  followupList &&
                  ![
                    1,
                    ...mainCancellationScreenSteps,
                    ...completionScreenSteps,
                  ].includes(followup.id),
              }"
            >
              <WeekNoneV2
                v-if="followUpType == 'nothing'"
                :question="followup"
                :composite="composite"
                @submitAnswer="answerFollowUpQuestion"
                :disabled="buttonDisabled"
              />
              <WeekTreatment
                v-if="followUpType == 'treatmentform'"
                :body="followup"
                @submitAnswer="answerFollowUpQuestion"
                :parent-ref="$refs.modalParent"
              />
              <WeekSingleV2
                v-if="followUpType == 'single'"
                :question="followup"
                :composite="composite"
                :mainScreenSteps="[
                  ...mainCancellationScreenSteps,
                  ...completionScreenSteps,
                ]"
                @submitAnswer="answerFollowUpQuestion"
                @discount="onDiscount"
                @getAnswer="setFollowUpMessage"
                :disabled="buttonDisabled"
                :followupId="followUpID"
                :patientid="patientid"
              />
              <WeekTextV2
                v-if="followUpType == 'text'"
                :question="followup"
                :composite="composite"
                @submitAnswer="answerFollowUpQuestion"
                :disabled="buttonDisabled"
              />
              <WeekMultiple
                v-if="followUpType == 'array'"
                :body="followup"
                @submitAnswer="answerFollowUpQuestion"
                :disabled="buttonDisabled"
              />
              <WeekSymptoms
                v-if="followUpType == 'symptoms'"
                :key="hasFollowupObject && followup.question"
                :disabled="buttonDisabled"
                :body="followup"
                @submitSymptoms="submitSymptoms"
              />

              <div v-if="composite" class="mt-4">
                <WeekTextV2
                  v-if="
                    composite &&
                    secondQuestionBody.answertype == 'text' &&
                    followupList[1].requiredifvalue == firstAnswer
                  "
                  :question="secondQuestionBody"
                  @submitAnswer="answerFollowUpQuestion"
                  :disabled="buttonDisabled"
                />
              </div>
            </div>
            <div class="col-lg-3 col-xl-4 col-xxl-4 col-12"></div>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </modal>
</template>

<script>
import _ from "lodash";

import { ChatService } from "@/services/chat.service.js";

import WeekNoneV2 from "@/components/10week/WeekNoneV2";
import WeekSingleV2 from "@/components/10week/WeekSingleV2";
import WeekTextV2 from "@/components/10week/WeekTextV2";
import FollowupHeader from "@/components/partials/FollowupHeader.vue";
import WeekMultiple from "@/components/10week/WeekMultiple";
import WeekSymptoms from "@/components/10week/WeekSymptoms";
import WeekTreatment from "@/components/10week/WeekTreatment";

export default {
  props: {
    messages: {
      type: Array,
      default: () => [],
    },
    patientid: {
      type: String,
      default: "",
    },
  },
  components: {
    WeekNoneV2,
    WeekSingleV2,
    WeekTextV2,
    FollowupHeader,
    WeekMultiple,
    WeekSymptoms,
    WeekTreatment,
  },
  data() {
    return {
      mainCancellationScreenSteps: [
        3.11, 3.21, 3.31, 3.41, 3.51, 3.71, 3.72, 3.81,
      ],
      completionScreenSteps: [12.2, 12.3, 12.4, 12.5],

      followupList: [],
      followUpID: "",
      composite: false,
      firstAnswer: null,
      followUpType: "treatment",
      followUpAnswers: [],
      secondQuestionBody: {},
      previousFollowUpID: "",

      buttonDisabled: false,

      isApplyDiscount: false,
    };
  },
  computed: {
    isMainCancellationScreen() {
      if (this.followup?.id) {
        return this.mainCancellationScreenSteps.includes(this.followup.id);
      } else {
        return false;
      }
    },
    isCompletionScreen() {
      if (this.followup?.id) {
        return this.completionScreenSteps.includes(this.followup.id);
      } else {
        return false;
      }
    },
    followup() {
      if (this.followupList.length > 0) {
        return this.followupList[0];
      } else {
        return {};
      }
    },
    hasFollowupObject() {
      return !_.isEmpty(this.followup);
    },
  },
  watch: {
    followupList() {
      if (this.followupList.length > 0) {
        if (
          ["symptom", "sideeffect"].includes(
            this.followupList[0]?.treatmentoption
          )
        ) {
          this.followUpType = "symptoms";
        } else if (
          ["recommendation", "methodchange_recommendation"].includes(
            this.followupList[0]?.answerlookup
          )
        ) {
          this.followUpType = "treatmentform";
        } else {
          this.followUpType = this.followupList[0].answertype;
        }
      }
    },
  },
  methods: {
    async submitSymptoms(selected, otherAnswer) {
      try {
        // let params;
        // if (hasOthers) {
        //   this.symptoms.splice(this.symptoms.indexOf("others"), 1);
        //   params = {
        //     symptomid: this.symptoms,
        //     othersymptom: selected,
        //   };
        // } else {
        //   params = {
        //     symptomid: selected,
        //   };
        // }
        // console.log(selected);
        // return;
        this.buttonDisabled = true;
        const { data } = await ChatService.answerFollowUpQuestion(
          this.followUpID,
          this.currentFollowupStep,
          {
            answers: [
              {
                questionid: this.currentFollowupStep,
                answer: selected,
                ...(otherAnswer && { others: [otherAnswer] }),
              },
            ],
          }
        );
        await this.setFollowUpMessage(this.followUpID, data.next);
        this.currentFollowupStep = data.next;
        this.buttonDisabled = false;
        // this.loadHealthQuestionStep(data.data.onboardingstep);
      } catch (err) {
        this.buttonDisabled = false;
        this.showAlert(err);
      }
    },
    closeModal() {
      this.$refs.modalv5.$children[0].hide();
      this.$emit("hide");
      // this.secondQuestionBody = {};
      // this.previousFollowUpID = "";
      // this.followUpAnswers = [];
    },
    async setFollowUpMessage(messageid, step = 1) {
      this.composite = false;
      this.followUpID = messageid;
      try {
        this.secondQuestionBody = {};
        this.buttonDisabled = true;
        const followup = await ChatService.getFollowUpQuestion(messageid, step);
        this.buttonDisabled = false;

        // Answer followupquestion if recommendation engine has an empty answerlist
        if (
          ["recommendation", "methodchange_recommendation"].includes(
            followup.data?.[0]?.answerlookup
          ) &&
          followup.data?.[0]?.answerlist.length === 0
        ) {
          this.answerFollowUpQuestion(
            followup.data?.[0]?.id,
            [],
            followup.data?.[0]
          );
          return;
        }

        if (followup.data) {
          const fmessage = this.messages.find((x) => x.id === messageid);
          this.followupList = followup.data;
          this.followupList.actionstatus = fmessage.actionstatus;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async answerFollowUpQuestion(
      id,
      answer,
      answerBody = null,
      moreQuestions,
      treatmentformanswer
    ) {
      try {
        const messageid = this.followUpID;

        // * Previous answer overwriting
        this.followUpAnswers = this.followUpAnswers.filter((o) => {
          return o.questionid !== id;
        });
        if (id === this.followup?.id) {
          this.firstAnswer = answer;
        }

        if (
          Boolean(answerBody) &&
          answerBody.hasOwnProperty("inline") &&
          answerBody.inline
        ) {
          this.followUpAnswers = [];
          this.composite = true;
          this.previousFollowUpID = id;
          const CompositeFollowUpRequest =
            await ChatService.getFollowUpQuestion(messageid, answerBody.next);
          this.secondQuestionBody = CompositeFollowUpRequest.data[0];
          this.followUpAnswers.push({ questionid: id, answer: answer });
        } else {
          this.followUpAnswers.push({
            questionid: id,
            answer: answer,
            ...(moreQuestions && { others: [moreQuestions] }),
          });

          // Check for composite answers
          const baseId =
            this.followUpAnswers.length > 1 ? this.previousFollowUpID : id;
          this.buttonDisabled = true;

          const res = await ChatService.answerFollowUpQuestion(
            messageid,
            baseId,
            {
              answers: this.followUpAnswers,
            }
          );

          this.followUpAnswers = [];
          if (res.data.next === -1) {
            this.$refs.modalv5.$children[0].hide();
            this.$emit("hide");

            return;
          } else {
            if (
              answerBody?.type === "treatmentform" &&
              answerBody?.answerlist.length !== 0
            ) {
              const treatmentAnswerList = [];
              const treatmentAnswer = treatmentformanswer ? "yes" : "unsure";
              treatmentAnswerList.push({
                questionid: answerBody.next,
                answer: treatmentAnswer,
              });
              const treatmentResponse =
                await ChatService.answerFollowUpQuestion(
                  messageid,
                  answerBody.next,
                  {
                    answers: treatmentAnswerList,
                  }
                );
              await this.setFollowUpMessage(
                this.followUpID,
                treatmentResponse.data.next
              );
              this.currentFollowupStep = treatmentResponse.data.next;
              this.buttonDisabled = false;
            } else {
              await this.setFollowUpMessage(this.followUpID, res.data.next);
              this.currentFollowupStep = res.data.next;
              this.buttonDisabled = false;
            }
          }
        }
      } catch (error) {
        this.buttonDisabled = false;
        console.log(error);
      }
    },
    async getPreviousFollowUpQuestion() {
      if (this.followup?.id == 1) {
        this.$refs.modalv5.$children[0].hide();
        this.$emit("hide");
      } else {
        this.composite = false;
        this.followUpAnswers = [];
        this.secondQuestionBody = {};
        const res = await ChatService.getPreviousFollowUpQuestion(
          this.followUpID,
          this.followup?.id
        );
        if (res.data.length > 0) {
          this.currentFollowupStep = res.data[0].id;
        }
        if (res.data) {
          this.followupList = res.data;
          if (res.data.length > 1) {
            this.composite = true;
            this.firstAnswer = res.data[0].answer;
            this.secondQuestionBody = res.data[1];
          }
        }
      }
    },
    onDiscount(val) {
      this.isApplyDiscount = val;
    },
  },
};
</script>

<style></style>

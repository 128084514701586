var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "week-single" },
    [
      _c("b-col", { attrs: { cols: "3" } }),
      _vm.mainScreenSteps.includes(_vm.question.id)
        ? _c("b-col", [
            _vm.question.id === 3.51
              ? _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column mx-auto",
                    staticStyle: { "max-width": "768px" },
                  },
                  [
                    _c(
                      "h2",
                      { staticClass: "question-title-v2 mb-3 text-white" },
                      [_vm._v(" " + _vm._s(_vm.question.title) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "mt-3 mb-3" },
                      _vm._l(_vm.question.displayArray, function (item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "d-flex align-items-center mb-3",
                          },
                          [
                            _c("div", {
                              staticStyle: {
                                width: "50px",
                                height: "50px",
                                "background-color": "#cdfcb1",
                                "border-radius": "999px",
                              },
                            }),
                            _c(
                              "p",
                              { staticClass: "sub-text text-white mb-0 ml-3" },
                              [_vm._v(_vm._s(item.name))]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _c(
                      "p",
                      { staticClass: "sub-text group-mb mb-4 text-white" },
                      [_vm._v(" " + _vm._s(_vm.question.question) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "cancel-membership" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "px-6",
                            attrs: { pill: "", variant: "info", size: "lg" },
                            on: {
                              click: function ($event) {
                                return _vm.submitAnswer(
                                  _vm.question.id,
                                  _vm.question.answerlist[0].id,
                                  _vm.question.answerlist[0]
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.question.answerlist[0].name) +
                                " "
                            ),
                          ]
                        ),
                        _c("div", { staticClass: "mt-4 pt-1" }, [
                          _c(
                            "a",
                            {
                              staticClass:
                                "text-underline text-uppercase text-white",
                              staticStyle: { "letter-spacing": "0.1rem" },
                              attrs: { href: "javascript:void(0)" },
                            },
                            [_vm._v(" Cancel membership ")]
                          ),
                        ]),
                      ],
                      1
                    ),
                  ]
                )
              : _vm.question.id === 3.71
              ? _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column mx-auto",
                    staticStyle: { "max-width": "768px" },
                  },
                  [
                    _c(
                      "h2",
                      { staticClass: "question-title-v2 mb-4 text-white" },
                      [_vm._v(" " + _vm._s(_vm.question.question) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "mb-2" },
                      [
                        _vm._l(_vm.question.answerlist, function (item, i) {
                          return _c("w-checkbox", {
                            key: item.name + i,
                            attrs: {
                              id: item.name + _vm.question.id,
                              value: item.id,
                              type: "radio",
                              label: item.name,
                              "is-cancel": true,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.submitAnswer(
                                  _vm.question.id,
                                  item.id,
                                  item
                                )
                              },
                            },
                            model: {
                              value: _vm.answer,
                              callback: function ($$v) {
                                _vm.answer = $$v
                              },
                              expression: "answer",
                            },
                          })
                        }),
                        _c(
                          "div",
                          { staticClass: "text-center" },
                          [
                            _vm.question.hasOwnProperty("answer")
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "mt-4",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      variant: "info",
                                      size: "lg",
                                      pill: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.submitAnswer(
                                          _vm.question.id,
                                          _vm.answer,
                                          _vm.question.answerlist.find(
                                            (o) => o.id == _vm.answer
                                          )
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("Continue")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "cancel-membership" }, [
                      _c("div", { staticClass: "mt-3 pt-1" }, [
                        _c(
                          "a",
                          {
                            staticClass:
                              "text-underline text-uppercase text-white",
                            staticStyle: { "letter-spacing": "0.1rem" },
                            attrs: { href: "javascript:void(0)" },
                          },
                          [_vm._v(" Cancel membership ")]
                        ),
                      ]),
                    ]),
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column mx-auto",
                    staticStyle: { "max-width": "768px" },
                  },
                  [
                    _c(
                      "h2",
                      { staticClass: "question-title-v2 mb-3 text-white" },
                      [_vm._v(" " + _vm._s(_vm.question.title) + " ")]
                    ),
                    _c(
                      "p",
                      { staticClass: "sub-text group-mb mb-4 text-white" },
                      [_vm._v(" " + _vm._s(_vm.question.question) + " ")]
                    ),
                    _c(
                      "div",
                      [
                        _vm.question.answerlist &&
                        _vm.question.answerlist.length > 0
                          ? _c(
                              "b-button",
                              {
                                staticClass: "px-6",
                                attrs: {
                                  pill: "",
                                  variant: "info",
                                  size: "lg",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitAnswer(
                                      _vm.question.id,
                                      _vm.question.answerlist[0].id,
                                      _vm.question.answerlist[0]
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.question.answerlist[0].name) +
                                    " "
                                ),
                              ]
                            )
                          : _c(
                              "b-button",
                              {
                                staticClass: "px-6",
                                attrs: {
                                  pill: "",
                                  variant: "info",
                                  size: "lg",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitAnswer(_vm.question.id, "")
                                  },
                                },
                              },
                              [_vm._v(" Complete ")]
                            ),
                        ![12.1, 12.2].includes(_vm.question.id)
                          ? _c("div", { staticClass: "mt-4 pt-1" }, [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "text-underline text-uppercase text-white",
                                  staticStyle: { "letter-spacing": "0.1rem" },
                                  attrs: { href: "javascript:void(0)" },
                                },
                                [_vm._v(" Cancel my subscription ")]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
          ])
        : [
            1, 2, 3.33, 3.41, 3.51, 3.81, 4.1, 4.3, 6.1, 7.1, 7.5, 8.1, 8.3,
            9.3, 11.1, 11.2,
          ].includes(_vm.question.id)
        ? _c("b-col", [
            _c(
              "div",
              {
                staticClass: "d-flex flex-column mx-auto",
                staticStyle: { "max-width": "768px" },
              },
              [
                _c("h2", { staticClass: "question-title-v2 mb-3" }, [
                  _vm._v(" " + _vm._s(_vm.question.title) + " "),
                ]),
                _c("p", { staticClass: "sub-text group-mb mb-4" }, [
                  _vm._v(" " + _vm._s(_vm.question.question) + " "),
                ]),
                _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "px-6",
                        attrs: { pill: "", variant: "info", size: "md" },
                        on: {
                          click: function ($event) {
                            return _vm.submitAnswer(
                              _vm.question.id,
                              _vm.question.answerlist[0].id,
                              _vm.question.answerlist[0]
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.question.answerlist[0].name) + " "
                        ),
                      ]
                    ),
                    _vm.question.answerlist.length > 1
                      ? _c("div", { staticClass: "mt-3 pt-1" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-default text-underline",
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitAnswer(
                                    _vm.question.id,
                                    _vm.question.answerlist[1].id,
                                    _vm.question.answerlist[1]
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.question.answerlist[1].name) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _vm.question.hasOwnProperty("displayArray")
        ? _c("b-col", [
            _c(
              "div",
              {
                staticClass: "d-flex flex-column mx-auto",
                staticStyle: { "max-width": "768px" },
              },
              [
                _c("h2", { staticClass: "question-title-v2 mb-3" }, [
                  _vm._v(_vm._s(_vm.question.title)),
                ]),
                _c(
                  "div",
                  { staticClass: "accordion mb-3", attrs: { role: "tablist" } },
                  _vm._l(_vm.question.displayArray, function (list, i) {
                    return _c(
                      "b-card",
                      {
                        key: i,
                        staticClass: "mb-0",
                        staticStyle: { border: "none" },
                        attrs: { "no-body": "" },
                      },
                      [
                        _c(
                          "b-card-header",
                          {
                            staticClass: "p-1 mb-3",
                            staticStyle: { border: "none" },
                            attrs: { "header-tag": "header", role: "tab" },
                          },
                          [
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle",
                                    value: "accordion-" + i,
                                    expression: "'accordion-' + i",
                                  },
                                ],
                                staticClass:
                                  "toggle-accordion bold d-letter-spacing",
                              },
                              [
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "h1 mb-0",
                                      staticStyle: { color: "#2c2660" },
                                    },
                                    [_vm._v(_vm._s(list.title))]
                                  ),
                                ]),
                                _c(
                                  "span",
                                  {
                                    staticClass: "when-opened text-underline",
                                    staticStyle: { color: "#a783ff" },
                                  },
                                  [_vm._v(" COLLAPSE ")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "when-closed text-underline",
                                    staticStyle: { color: "#a783ff" },
                                  },
                                  [_vm._v(" EXPAND ")]
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "b-collapse",
                          {
                            attrs: {
                              id: "accordion-" + i,
                              accordion: "my-accordion",
                              role: "tabpanel",
                              visible: i === 0,
                            },
                          },
                          [
                            _c(
                              "b-card-text",
                              {
                                staticClass: "pb-4 d-letter-spacing text-muted",
                              },
                              [_vm._v(_vm._s(list.name))]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "px-6",
                        attrs: { pill: "", variant: "info", size: "md" },
                        on: {
                          click: function ($event) {
                            return _vm.submitAnswer(
                              _vm.question.id,
                              _vm.question.answerlist[0].id,
                              _vm.question.answerlist[0]
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.question.answerlist[0].name) + " "
                        ),
                      ]
                    ),
                    _vm.question.answerlist.length > 1
                      ? _c("div", { staticClass: "mt-3 pt-1" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-default text-underline",
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitAnswer(
                                    _vm.question.id,
                                    _vm.question.answerlist[1].id,
                                    _vm.question.answerlist[1]
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.question.answerlist[1].name) +
                                  " "
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _c(
            "b-col",
            {
              staticClass: "d-flex flex-column mx-auto",
              attrs: { "align-self": "center" },
            },
            [
              _c("h1", { staticClass: "question-title-v2" }, [
                _vm._v(" " + _vm._s(_vm.question.question) + " "),
              ]),
              _c(
                "div",
                { staticClass: "text-center" },
                _vm._l(_vm.question.answerlist, function (item, i) {
                  return _c("w-checkbox", {
                    key: item.name + i,
                    attrs: {
                      id: item.name + _vm.question.id,
                      value: item.id,
                      type: "radio",
                      label: item.name,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.submitAnswer(_vm.question.id, item.id, item)
                      },
                    },
                    model: {
                      value: _vm.answer,
                      callback: function ($$v) {
                        _vm.answer = $$v
                      },
                      expression: "answer",
                    },
                  })
                }),
                1
              ),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _vm.question.hasOwnProperty("answer") && !_vm.composite
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mt-4",
                          staticStyle: { width: "100%" },
                          attrs: { variant: "info", size: "lg", pill: "" },
                          on: {
                            click: function ($event) {
                              _vm.submitAnswer(
                                _vm.question.id,
                                _vm.answer,
                                _vm.question.answerlist.find(
                                  (o) => o.id == _vm.answer
                                )
                              )
                            },
                          },
                        },
                        [_vm._v("Continue")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="card-holder mb-4" v-if="prescription">
    <div
      class="
        card-image-container
        img-holder
        d-flex
        justofy-content-center
        align-items-center
      "
      style="height: auto !important"
    >
      <img :src="prescription.image" style="object-fit: cover; height: 100%" />
    </div>
    <div class="card-content">
      <h3 class="text-default bold-font" style="letter-spacing: 0.01em">
        {{ prescription.name }}
      </h3>
      <p class="mb-4">
        {{ prescription.description }}
      </p>

      <p class="mb-0 bold-font">
        Available monthly (starting at
        {{ prescription.regularprice }}) and quarterly (starting at
        {{ prescription.discountedprice }})
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    prescription: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-holder {
  display: flex;
  background: #f8f8f8;

  .card-image-container img {
    object-fit: cover;
    width: 200px;
    height: 200px;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    display: block;
  }

  .card-content {
    padding: 1.5rem;
  }
}

.img-holder {
  width: 100% !important;
  height: 100% !important;
  border-right: 1px solid #edf2f9;
  background-color: #f1f5f1;
  margin-right: 0 !important;

  @include media-breakpoint-down(md) {
    height: 200px !important;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function waitUntilSet(variable) {
  return new Promise((resolve) => {
    function checkVariable() {
      if (variable) {
        resolve();
      } else {
        requestAnimationFrame(checkVariable);
      }
    }
    checkVariable();
  });
}

export function isIterable(obj) {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }

  return typeof obj[Symbol.iterator] === "function";
}

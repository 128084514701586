<template>
  <b-row align-v="stretch" class="week-nothing">
    <b-col v-if="question.type == 'exitmodal'" align-self="center">
      <b-row align-h="center">
        <b-col cols="12" md="6">
          <h1 class="text-center question-title-v2 mb-4">
            {{ question.title }}
          </h1>
          <!-- <p class="sub-text group-mb text-center text-muted mb-4">
            {{ question.description }}
          </p> -->

          <b-row align-v="center" align-h="center" class="mt-5 mb-4 mx-auto">
            <b-col cols="auto">
              <b-avatar
                variant="primary"
                :src="question.doctor.profileUrl"
                size="7rem"
              ></b-avatar>
            </b-col>
            <b-col cols="auto">
              <h2 class="mb-0 bold d-letter-spacing">
                Dr. {{ question.doctor.firstname }}
                {{ question.doctor.lastname }}
              </h2>
              <!-- <p class="mb-0 loader-sub-text">License #: G80363</p> -->
            </b-col>
          </b-row>

          <b-button
            class="px-6 mt-4 mx-auto d-block"
            pill
            variant="info"
            size="lg"
            @click="submitAnswer"
            :disabled="disabled"
            >Continue</b-button
          >
        </b-col>
      </b-row>
    </b-col>
    <b-col v-else align-self="center" class="d-flex flex-column">
      <h2 class="text-center question-title-v2 mb-2">{{ question.title }},</h2>
      <p class="sub-text group-mb text-center mb-4" v-if="question.description">
        {{ question.description }}
      </p>
      <div class="align-self-center">
        <b-button
          class="px-6"
          pill
          variant="info"
          size="lg"
          @click="submitAnswer"
          :disabled="disabled"
          >Continue</b-button
        >
      </div>
      <!-- <div class="align-self-center mt-3 pt-1">
        <a href="#" class="text-default text-underline"
          >I don’t like to continue</a
        >
      </div> -->
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
    },
  },
  data() {
    return {
      disabled: false,
    };
  },
  methods: {
    submitAnswer() {
      this.disabled = true;
      this.$emit("submitAnswer", this.question.id, "");
      this.disabled = false;
    },
  },
};
</script>

<style></style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.prescription
    ? _c("div", { staticClass: "card-holder mb-4" }, [
        _c(
          "div",
          {
            staticClass:
              "card-image-container img-holder d-flex justofy-content-center align-items-center",
            staticStyle: { height: "auto !important" },
          },
          [
            _c("img", {
              staticStyle: { "object-fit": "cover", height: "100%" },
              attrs: { src: _vm.prescription.image },
            }),
          ]
        ),
        _c("div", { staticClass: "card-content" }, [
          _c(
            "h3",
            {
              staticClass: "text-default bold-font",
              staticStyle: { "letter-spacing": "0.01em" },
            },
            [_vm._v(" " + _vm._s(_vm.prescription.name) + " ")]
          ),
          _c("p", { staticClass: "mb-4" }, [
            _vm._v(" " + _vm._s(_vm.prescription.description) + " "),
          ]),
          _c("p", { staticClass: "mb-0 bold-font" }, [
            _vm._v(
              " Available monthly (starting at " +
                _vm._s(_vm.prescription.regularprice) +
                ") and quarterly (starting at " +
                _vm._s(_vm.prescription.discountedprice) +
                ") "
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
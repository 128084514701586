<template>
  <div class="pl-3 mx-auto" style="max-width: 768px">
    <h2 class="question-title">{{ body.question }}</h2>
    <b-alert :show="alert.show" :variant="alert.type">{{
      alert.message
    }}</b-alert>
    <b-row>
      <b-col cols="12" class="fs-exclude mb-2 pl-0 pr-0">
        <ul class="list-unstyled symptoms justify-content-center">
          <li v-for="(list, i) in symptoms" :key="i">
            <input
              type="checkbox"
              :id="'checkbox-' + i"
              v-model="selected"
              :value="list.id"
            />
            <label :for="'checkbox-' + i">
              <v-icon
                :name="list.id"
                :size="['acne'].includes(list.id) ? '50' : '35'"
              ></v-icon>
              <span>{{ list.name }}</span>
            </label>
          </li>
          <li v-if="body.treatmentoption === 'sideeffect'">
            <input
              type="checkbox"
              :id="'checkbox-' + symptoms.length + 1"
              v-model="selected"
              value="others"
            />
            <label :for="'checkbox-' + symptoms.length + 1">
              <v-icon name="othersideeffect" size="35"></v-icon>
              <span>Others</span>
            </label>
          </li>
        </ul>
      </b-col>
      <b-col
        cols="12"
        class="mb-4 pl-0 pr-0"
        v-if="hasOthers && body.treatmentoption === 'sideeffect'"
      >
        <h3>What are your added side effects?</h3>
        <b-form-textarea
          id="textarea"
          class="onboarding-textarea-v2"
          style="width: 100%"
          v-model.trim="otherAnswer"
          rows="6"
          max-rows="6"
          placeholder="Write something"
        ></b-form-textarea>
      </b-col>
      <b-col cols="12" class="pl-0">
        <b-button
          pill
          variant="info"
          class="btn mb-3"
          :disabled="disabled"
          @click="submitSymptoms"
          >Continue</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { LookUpService } from "@/services/lookup.service";
import { OnboardingService } from "@/services/onboarding.service";

export default {
  props: {
    selectedSymptoms: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
    body: {
      type: Object,
    },
  },
  data() {
    return {
      // Alert
      alert: {
        show: false,
        type: "",
        message: "",
      },
      // Symptoms
      symptoms: [],
      selected: [],
      otherAnswer: "",
    };
  },
  created() {
    this.symptoms = this.body.answerlist;
    if (this.body?.answer?.length > 0) {
      this.selected = this.body.answer;
    }
    if (this.body?.others?.length > 0) {
      this.otherAnswer = this.body?.others[0];
    }
  },
  computed: {
    hasOthers() {
      return this.selected.includes("others");
    },
  },
  methods: {
    async populateSymptoms() {
      if (this.symptoms.length === 0) {
        const symptomData = await LookUpService.getLookUp(this.lookup);
        this.symptoms = symptomData.data;
      }
    },
    async populateSelectedSymptoms() {
      const { data } = await OnboardingService.getPatientOnboarding();
      if (data.hasOwnProperty("symptomid")) {
        this.selected = data.symptomid;
        // if(data.hasOwnProperty('othersymptom')) {
        //   this.selected.push('others')
        // }
      } else if ($cookies.get("winona-quiz-v2-answers")) {
        const quizanswers = $cookies.get("winona-quiz-v2-answers");
        // Remove "other" symptom to prevent showing the alternate question
        this.selected = quizanswers.selectedSymptoms.filter(
          (e) => e !== "other"
        );
      } else {
        this.selected = [];
      }
    },
    submitSymptoms() {
      this.selected = this.selected.filter((item) => item);
      this.$emit("submitSymptoms", this.selected, this.otherAnswer);
    },
  },
  async mounted() {
    // this.populateSymptoms();
    // this.populateSelectedSymptoms();
  },
};
</script>

<template>
  <b-card no-body style="border: 0">
    <b-row no-gutters>
      <b-col cols="auto" class="mr-1">
        <b-form-checkbox
          :id="'checkbox-' + product.id"
          v-model="selected"
          name="product-id"
          class="product-reveal-checkbox"
          :unchecked-value="false"
        >
          <!-- Deselect is here -->
        </b-form-checkbox>
      </b-col>
      <b-col>
        <b-row no-gutters style="position: relative; background-color: #f8f8f8">
          <b-col cols="12" lg="4">
            <div class="img-holder">
              <img
                :src="
                  product.image == null
                    ? require('@/assets/images/dashboard/articlebg.png')
                    : product.image
                "
              />
            </div>
          </b-col>
          <b-col cols="12" lg="8" class="px-4 py-4">
            <h3 class="text-default bold-font" style="letter-spacing: 0.01em">
              {{ product.name }}
            </h3>
            <!-- Product description -->
            <p class="mb-0">{{ descriptionText }}</p>
            <small class="d-block mb-3" v-if="product.description">
              <a
                href="javascript:void(0)"
                @click="showDescription = !showDescription"
              >
                {{ readMoreText }}
              </a>
            </small>

            <!-- Pricing selection -->
            <!-- <ul class="list-unstyled list-inline">
              <li
                v-for="pricing in product.availablepricing"
                :key="pricing.pricingid"
              >
                <input
                  @click="setPricing(pricing.pricingid)"
                  v-model="selectedpricing"
                  :value="pricing.pricingid"
                  :id="pricing.pricingid"
                  type="radio"
                  :name="product.id"
                />
                <label :for="pricing.pricingid" class="subscription-label">
                  ${{ pricing.price }} - {{ pricing.name }}
                </label>
              </li>
            </ul> -->
            <p class="mb-0 bold-font" v-if="product.id !== 'nopreference'">
              Available monthly (starting at
              {{ product.regularprice }}) and quarterly (starting at
              {{ product.discountedprice }})
            </p>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
export default {
  props: {
    product: {
      type: Object,
    },
    desiredplan: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    descriptionText() {
      return this.showDescription
        ? this.product.moredescription
        : this.product.description;
    },
    readMoreText() {
      return this.showDescription ? "Read Less" : "Read More";
    },
  },
  data() {
    return {
      showDescription: false,
      selected: false,
    };
  },
  async mounted() {
    // this.selectedpricing = this.product.availablepricing[0].pricingid;
    // this.selected = true;
  },
  watch: {
    selected(val) {
      this.$emit("set-selection", this.product, val);
    },
    desiredplan(val) {
      if (val.includes(this.product.id)) {
        this.selected = true;
      } else {
        this.selected = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  position: absolute;
  top: 10px;
  padding: 5px 10px;
  background: #ff8d40;
  right: 0;
  color: $white;
  text-transform: uppercase;
  letter-spacing: 0.16em;
  font-size: 10px;
  z-index: 1;
}

.list-inline {
  margin-top: 1.5rem;

  li {
    position: relative;
    width: 50%;
    display: inline-block;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    input {
      position: absolute;
      left: -9999px;
    }

    label {
      position: relative;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        display: inline-flex;
        flex: 1 150vw;
        width: 15px;
        height: 15px;
        max-width: 15px;
        border-radius: 15px;
        border: 1px solid $primary;
        margin-right: 10px;

        @include media-breakpoint-up(xxl) {
          width: 15px * $scale-font;
          height: 15px * $scale-font;
          max-width: 15px * $scale-font;
          border-radius: 15px * $scale-font;
          margin-right: 10px * $scale-font;
        }
      }
    }

    input:checked + label {
      &:before {
        background-color: $primary;
      }
    }
  }
}

.img-holder {
  width: 100% !important;
  height: 100% !important;
  border-right: 1px solid #edf2f9;
  background-color: #f1f5f1;
  margin-right: 0 !important;

  @include media-breakpoint-down(md) {
    height: 200px !important;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>

<template>
  <div class="week-single">
    <b-col cols="3"></b-col>
    <b-col
      v-if="question.hasOwnProperty('type') && question.type == 'modal'"
      align-self="center"
    >
      <b-modal
        size="md"
        hide-header
        hide-footer
        centered
        content-class="dark-bg"
        no-close-on-backdrop
        no-close-on-esc
        v-model="showModal"
      >
        <b-row class="mb-6">
          <b-col cols="12">
            <div class="icon-logo dark"></div>
          </b-col>
        </b-row>
        <div class="modal-card">
          <h2
            class="mb-3 heading color-inherit"
            style="font-size: 26px; line-height: 32px"
          >
            {{ question.title }}
          </h2>
          <b-button
            variant="info"
            pill
            block
            v-for="(answer, i) in question.answerlist"
            :key="answer.name + i"
            @click="submitAnswer(question.id, answer.id, answer)"
            >{{ answer.name }}</b-button
          >
        </div>
      </b-modal>
    </b-col>
    <b-col v-else-if="question.id == 1" align-self="center" class="d-flex flex-column">
      <h2 class="text-center question-title-v2 mb-2">{{ question.title }},</h2>
      <p class="sub-text group-mb text-center mb-4">
        {{ question.question }}
      </p>
      <div class="align-self-center">
        <b-button 
          class="px-6" 
          pill 
          variant="info" 
          size="lg" 
          @click="submitAnswer(question.id, question.answerlist[0].id, question.answerlist[0])"
        >
          {{ question.answerlist[0].name }}
        </b-button>
        <div class="align-self-center mt-3 pt-1" v-if="question.answerlist.length > 1">
          <a 
            href="javascript:void(0)" 
            class="text-default text-underline"
            @click="submitAnswer(question.id, question.answerlist[1].id, question.answerlist[1])"
          >
            {{ question.answerlist[1].name }}
          </a>
        </div>
      </div>
    </b-col>
    <b-col v-else align-self="center" class="d-flex flex-column">
      <h1 class="text-center question-title-v2">
        {{ question.question }}
      </h1>
      <div class="text-center">
        <b-button
          variant="outline-info"
          size="xl"
          v-for="(item, i) in question.answerlist"
          :key="item.name + i"
          :id="item.name + question.id"
          :value="item.id"
          :pressed="item.id == answer"
          class="mx-3 question-btn"
          :class="question.id == answer ? 'active' : ''"
          @click="submitAnswer(question.id, item.id, item)"
        >
          {{ item.name }}
        </b-button>
      </div>
      <div class="text-center">
        <b-button
          class="mt-4"
          v-if="question.hasOwnProperty('answer') && !composite"
          variant="info"
          size="lg"
          pill
          @click="
            submitAnswer(
              question.id,
              answer,
              question.answerlist.find((o) => o.id == answer)
            )
          "
          >Continue</b-button
        >
      </div>
    </b-col>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
      default() {
        return {};
      },
    },
    composite: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      answer: null
    };
  },
  watch: {
    question: {
      handler: function() {
        this.answer = null;
        if (this.question.hasOwnProperty("type") && this.question.type == "modal") {
          this.showModal = true;
        }
        if (this.question.hasOwnProperty("answer")) {
          this.answer = this.question.answer;
        }
      },
      deep: true,
    },
  },
  methods: {
    submitAnswer(id, answer, answerBody) {
      this.showModal = false;
      this.answer = answer;
      this.$emit("submitAnswer", id, answer, answerBody);
    },
  },
  mounted () {
    if (this.question.hasOwnProperty('answer')) {
      this.answer = this.question.answer
    }
  }
};
</script>

<style></style>

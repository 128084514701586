<template>
  <div class="week-text">
    <h1 class="text-center question-title-v2 mb-4">
      {{ question.question }}
    </h1>
    <div class="text-center">
      <b-form-textarea
        id="textarea"
        style="width: 100%"
        v-model="answer"
        rows="6"
        max-rows="6"
        placeholder="Write something"
      ></b-form-textarea>
      <b-button
        variant="info"
        pill
        class="mt-3 onboarding-btn"
        @click="submitAnswer"
        >Continue</b-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    question: {
      type: Object,
    },
  },
  data() {
    return {
      answer: "",
    };
  },
  watch: {
    question: {
      handler() {
        this.answer = null;
        if (this.question?.answer) {
          this.answer = this.question.answer;
        }
      },
      deep: true,
    },
  },
  methods: {
    submitAnswer() {
      if (this.answer == "" && !this.question?.allowempty) {
        this.$emit("alert", "The text field cannot be empty");
      } else {
        this.$emit("submitAnswer", this.question.id, this.answer || "");
      }
    },
  },
  mounted() {
    if (this.question?.answer) {
      this.answer = this.question.answer;
    }
  },
};
</script>

<style></style>

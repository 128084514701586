<template>
  <div class="week-treatment">
    <b-col cols="3"></b-col>
    <b-col>
      <div class="d-flex flex-column mx-auto" style="max-width: 768px">
        <h2 class="question-title mb-5" v-if="hasDHEA">
          Based on your current symptoms and preferences, this treatment option
          may be a great addition for you, if your Winona physician agrees.
        </h2>
        <h2 class="question-title mb-5" v-else>
          Based on your current symptoms and preferences, these treatment
          options may be a better solution for you, if your Winona physician
          agrees.
        </h2>

        <section v-if="activePrescriptions.length > 0">
          <p class="sub-text mb-3 bold-font">Current Treatment</p>
          <CurrentTreatmentCard
            v-for="prescription in activePrescriptions"
            :prescription="prescription"
            :key="prescription.id"
          />
        </section>

        <section v-if="hasDHEA">
          <p class="sub-text mb-3 bold-font">Additional Treatment</p>
          <CurrentTreatmentCard
            v-for="prescription in products"
            :prescription="prescription"
            :key="prescription.id"
          />
        </section>
        <section v-else>
          <p class="sub-text mb-3 bold-font">Treatment</p>
          <ProductRevealCheckbox
            ref="ProductRevealCheckbox"
            v-for="product in products"
            :key="product.id"
            :product="product"
            :desiredplan="desiredplan"
            @set-selection="updateSelection"
          />
        </section>

        <section class="mb-5">
          <p class="sub-text mb-3 bold-font">Any questions for the doctor?</p>
          <b-form-textarea
            id="textarea"
            class="onboarding-textarea-v2"
            rows="4"
            max-rows="4"
            placeholder="Write something"
            style="width: 100%"
            v-model="doctorQuestions"
          ></b-form-textarea>
        </section>

        <section class="mb-5">
          <h2 class="question-title mb-5">
            Would you like us to let your doctor know your preference?
          </h2>
          <w-checkbox
            id="1"
            value="1"
            type="radio"
            label="Yes please!"
            v-model="answer"
          ></w-checkbox>
          <w-checkbox
            id="2"
            value="2"
            type="radio"
            label="I’m not sure - I’d like to message with my physician directly"
            v-model="answer"
          ></w-checkbox>
          <!-- <b-button
            class="mt-4"
            variant="info"
            size="md"
            pill
            @click="onProceedStep"
            style="width: 100%"
          >
            Continue
          </b-button> -->
        </section>

        <section>
          <p class="sub-text mb-3 bold-font">How does this help?</p>

          <div>
            <div
              v-for="blog in blogs"
              :key="blog.sys.id"
              class="card-holder mb-5"
              style="background: #fff"
            >
              <div class="card-image-container">
                <img :src="blog.thumbnailWebp.url" :alt="blog.title" />
              </div>
              <div class="card-content">
                <p class="mb-2 article-tag">{{ blog.category.title }}</p>
                <h2 class="article-title mb-2" style="font-size: 1.5rem">
                  {{ blog.title }}
                </h2>
                <a href="#" class="text-underline" style="font-size: 1.25rem">
                  Learn More
                  <span
                    class="next-arrow-purple"
                    style="font-size: 1rem"
                  ></span>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div>
    </b-col>
  </div>
</template>

<script>
import ProductRevealCheckbox from "@/components/10week/recommendation/ProductRevealCheckbox";
import CurrentTreatmentCard from "@/components/10week/recommendation/CurrentTreatmentCard";
export default {
  props: {
    body: {
      type: Object,
    },
  },
  components: {
    ProductRevealCheckbox,
    CurrentTreatmentCard,
  },
  data() {
    return {
      answer: false,
      blogs: [],
      desiredplan: [],
      doctorQuestions: "",
    };
  },
  computed: {
    products() {
      return this.body?.answerlist || [];
    },
    activePrescriptions() {
      return this.body?.active || [];
    },
    hasDHEA() {
      return this.products.some((product) => product.name === "DHEA");
    },
  },
  watch: {
    answer(val) {
      if (this.hasDHEA) {
        const dheaProduct = this.products.find(
          (product) => product.name === "DHEA"
        );
        this.$emit(
          "submitAnswer",
          this.body.id,
          [...this.desiredplan, dheaProduct.id],
          this.body,
          this.doctorQuestions,
          val
        );
      } else {
        this.$emit(
          "submitAnswer",
          this.body.id,
          this.desiredplan,
          this.body,
          this.doctorQuestions,
          val
        );
      }
    },
  },
  async created() {
    this.blogs = await this.getBlog();
  },
  methods: {
    getBlog: async () => {
      const query = `{
        journalCollection(limit:3, order:sys_publishedAt_ASC) {
          items {
            sys {
              id
            }
            title
            slug
            thumbnail {
              title
              url
            }
            thumbnailWebp {
              title
              url
            }
            category {
              sys {
                id
              }
              title
              slug
            }
          }
        }
      }`;
      const fetchUrl = `https://graphql.contentful.com/content/v1/spaces/${
        process.env.VUE_APP_CONTENTFUL_SPACE_ID
      }/environments/${
        process.env.VUE_APP_SERVER === "production" ? "main" : "staging"
      }`;
      const fetchOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.VUE_APP_CONTENTFUL_ACCESS_TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
        }),
      };
      try {
        const response = await fetch(fetchUrl, fetchOptions).then((response) =>
          response.json()
        );
        return response.data.journalCollection.items;
      } catch (error) {
        throw new Error("Could not receive the data from Contentful!");
      }
    },
    updateSelection(product, selected) {
      if (product.id === "nopreference") {
        if (selected) {
          this.desiredplan = ["nopreference"];
        }
        return;
      }

      if (selected) {
        if (this.desiredplan.includes("nopreference")) {
          this.desiredplan = this.desiredplan.filter(
            (plan) => plan !== "nopreference"
          );
        }
        if (!this.desiredplan.includes(product.id)) {
          this.desiredplan.push(product.id);
        }
      } else {
        this.desiredplan = this.desiredplan.filter(
          (plan) => plan !== product.id
        );
      }
    },
    onProceedStep() {
      if (this.hasDHEA) {
        const dheaProduct = this.products.find(
          (product) => product.name === "DHEA"
        );
        this.$emit(
          "submitAnswer",
          this.body.id,
          [...this.desiredplan, dheaProduct.id],
          this.body,
          this.doctorQuestions
        );
      } else {
        this.$emit(
          "submitAnswer",
          this.body.id,
          this.desiredplan,
          this.body,
          this.doctorQuestions
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-holder {
  display: flex;
  background: #f8f8f8;

  .card-image-container img {
    object-fit: cover;
    width: 200px;
    height: 200px;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  @include media-breakpoint-down(md) {
    display: block;
  }

  .card-content {
    padding: 1.5rem;
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "week-single" },
    [
      _c("b-col", { attrs: { cols: "3" } }),
      _vm.question.hasOwnProperty("type") && _vm.question.type == "modal"
        ? _c(
            "b-col",
            { attrs: { "align-self": "center" } },
            [
              _c(
                "b-modal",
                {
                  attrs: {
                    size: "md",
                    "hide-header": "",
                    "hide-footer": "",
                    centered: "",
                    "content-class": "dark-bg",
                    "no-close-on-backdrop": "",
                    "no-close-on-esc": "",
                  },
                  model: {
                    value: _vm.showModal,
                    callback: function ($$v) {
                      _vm.showModal = $$v
                    },
                    expression: "showModal",
                  },
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "mb-6" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("div", { staticClass: "icon-logo dark" }),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "modal-card" },
                    [
                      _c(
                        "h2",
                        {
                          staticClass: "mb-3 heading color-inherit",
                          staticStyle: {
                            "font-size": "26px",
                            "line-height": "32px",
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.question.title) + " ")]
                      ),
                      _vm._l(_vm.question.answerlist, function (answer, i) {
                        return _c(
                          "b-button",
                          {
                            key: answer.name + i,
                            attrs: { variant: "info", pill: "", block: "" },
                            on: {
                              click: function ($event) {
                                return _vm.submitAnswer(
                                  _vm.question.id,
                                  answer.id,
                                  answer
                                )
                              },
                            },
                          },
                          [_vm._v(_vm._s(answer.name))]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm.question.id == 1
        ? _c(
            "b-col",
            {
              staticClass: "d-flex flex-column",
              attrs: { "align-self": "center" },
            },
            [
              _c("h2", { staticClass: "text-center question-title-v2 mb-2" }, [
                _vm._v(_vm._s(_vm.question.title) + ","),
              ]),
              _c("p", { staticClass: "sub-text group-mb text-center mb-4" }, [
                _vm._v(" " + _vm._s(_vm.question.question) + " "),
              ]),
              _c(
                "div",
                { staticClass: "align-self-center" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "px-6",
                      attrs: { pill: "", variant: "info", size: "lg" },
                      on: {
                        click: function ($event) {
                          return _vm.submitAnswer(
                            _vm.question.id,
                            _vm.question.answerlist[0].id,
                            _vm.question.answerlist[0]
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.question.answerlist[0].name) + " "
                      ),
                    ]
                  ),
                  _vm.question.answerlist.length > 1
                    ? _c(
                        "div",
                        { staticClass: "align-self-center mt-3 pt-1" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "text-default text-underline",
                              attrs: { href: "javascript:void(0)" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitAnswer(
                                    _vm.question.id,
                                    _vm.question.answerlist[1].id,
                                    _vm.question.answerlist[1]
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.question.answerlist[1].name) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _c(
            "b-col",
            {
              staticClass: "d-flex flex-column",
              attrs: { "align-self": "center" },
            },
            [
              _c("h1", { staticClass: "text-center question-title-v2" }, [
                _vm._v(" " + _vm._s(_vm.question.question) + " "),
              ]),
              _c(
                "div",
                { staticClass: "text-center" },
                _vm._l(_vm.question.answerlist, function (item, i) {
                  return _c(
                    "b-button",
                    {
                      key: item.name + i,
                      staticClass: "mx-3 question-btn",
                      class: _vm.question.id == _vm.answer ? "active" : "",
                      attrs: {
                        variant: "outline-info",
                        size: "xl",
                        id: item.name + _vm.question.id,
                        value: item.id,
                        pressed: item.id == _vm.answer,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitAnswer(
                            _vm.question.id,
                            item.id,
                            item
                          )
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(item.name) + " ")]
                  )
                }),
                1
              ),
              _c(
                "div",
                { staticClass: "text-center" },
                [
                  _vm.question.hasOwnProperty("answer") && !_vm.composite
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mt-4",
                          attrs: { variant: "info", size: "lg", pill: "" },
                          on: {
                            click: function ($event) {
                              _vm.submitAnswer(
                                _vm.question.id,
                                _vm.answer,
                                _vm.question.answerlist.find(
                                  (o) => o.id == _vm.answer
                                )
                              )
                            },
                          },
                        },
                        [_vm._v("Continue")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }